import React from 'react';

import Layout from '../../components/layout';
import withBg from '../../helpers/withBg';
import SEO from '../../components/seo';
import TrustpilotHorizontal from '../../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../../components/Sidebar/SidebarStaticPage';
import { baseApiUrl } from '../../configs';

const data = {
  title: 'Win a Trip | Terms & Conditions',
  seoTitle: 'Win a Trip | Terms & Conditions | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class WinTripTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber,
      config: {}
    };
  }

  async componentDidMount() {
    // for winATrip config
    const configResponse = await fetch(`${baseApiUrl}/win-a-trip`);
    const configResponseJson = await configResponse.json();
    this.setState({ config: configResponseJson });
  }

  render() {
    const { phoneNumber, config } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <div
                  dangerouslySetInnerHTML={{
                    __html: config.terms_and_conditions
                  }}
                />
              </div>
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(WinTripTerms);
